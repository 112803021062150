<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row py-3 px-4" id="filter-card">
        <div class="col-lg-12 col-xl-12 px-0">
          <div
            class="row justify-content-start items-baseline w-100 mb-2"
            style="margin-bottom: -5px"
          >
            <span
              class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2"
              @click="$router.go(-1)"
            >
              <i class="fa fa-chevron-left"></i>
            </span>
            <h3 class="d-inline-block" id="page-title">
              {{ $t("general.edit_supplier_company").toUpperCase() }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box
        :back="true"
        back-component-name="management.supplier-company.index"
        class="px-5 py-4"
      >
        <template v-slot:preview>
          <div class="w-100 row mb-7">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                :has-bottom-margin="false"
                :is-inline="false"
                :model.sync="selectedCompaniesId"
                :options="
                  convertArrayToObjectByKey(supplierCompanies, 'logicalref', 'title')
                "
                :title="$t('supplier_company.supplier_companes')"
                :required="false"
                :showInvalid="false"
                input-width="150px"
                max="1"
                name="divided_product_instruction_type_id"
                not-list="true"
              />
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.name_supplier_company')}`"
                :model.sync="company.name"
                :is-valid="company.name != null && company.name.length !== 0"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.name_supplier_company')}`"
                :model.sync="company.logo_name"
              ></text-input>
            </div>
          </div>
          <div class="w-100 row mb-7">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.email')}`"
                type="email"
                :model.sync="company.email"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.telephone')}`"
                :model.sync="company.telephone"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.address')}`"
                :model.sync="company.address"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.logo_code')}`"
                type="string"
                :model.sync="company.logo_code"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.logo_id')}`"
                type="string"
                :model.sync="company.logo_id"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                :title="$t('general.currency_unit') + ':'"
                :model.sync="company.currency_unit_id"
                :options="currency_units"
                name="name"
                :not-list="true"
                :is-inline="true"
                :max="1"
                :item-per-row="1"
                input-width="auto"
                input-max-width="150px"
              ></custom-multi-select>
            </div>
          </div>
          <div class="d-flex justify-content-center w-100 row">
            <h3>Banka Hesapları</h3>
          </div>

          <div class="d-flex justify-content-center w-100 row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.account_holder_name')}`"
                type="string"
                :model.sync="company.bank_accounts.account_holder_name"
                :is-inline="false"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.bank_name')}`"
                type="string"
                :model.sync="company.bank_accounts.bank_name"
                :is-inline="false"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.bank_branch')}`"
                type="string"
                :model.sync="company.bank_accounts.bank_branch"
                :is-inline="false"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.debtor_account')}`"
                type="string"
                :model.sync="company.bank_accounts.debtor_account"
                :is-inline="false"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                :title="`${$t('supplier_company.iban')}`"
                type="string"
                :model.sync="company.bank_accounts.iban"
                :is-inline="false"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                :title="$t('general.currency_unit') + ':'"
                :model.sync="company.bank_accounts.currency_unit_id"
                :options="currency_units"
                name="name"
                :not-list="true"
                :is-inline="false"
                :max="1"
                :item-per-row="1"
                input-width="auto"
                input-max-width="150px"
              ></custom-multi-select>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button-with-icon
              class="mr-3 danger-border-1px"
              @onClick="$router.push({ name: 'management.supplier-company.index' })"
              :text="$t('general.cancel')"
              size="lg"
              :icon-name="null"
            ></button-with-icon>
            <button-with-icon
              @onClick="createOrUpdate"
              :text="$t(id != null ? 'general.save' : 'general.create')"
              size="lg"
              :icon-name="null"
              :disabled="!isValidToSubmit"
              class="mr-3 danger-border-1px"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import TextInput from "@/assets/components/inputs/TextInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";

import {
  ERROR,
  LOADING,
  SUCCESS,
  CREATE_ITEM,
  GET_ITEMS,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
  name: "SupplierCompanyCreateEdit",
  components: {
    ButtonWithIcon,
    TextInput,
    DashboardBox,
    CustomMultiSelect,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
    }),
    isValidToSubmit() {
      return true;
    },
  },
  data() {
    return {
      id: null,
      currency_units: null,
      currency_units_raw: null,
      bank_accounts: {
        bank_name: null,
        bank_branch: null,
        debtor_account: null,
        iban: null,
        account_holder_name: null,
        currency_unit_id: null,
      },
      company: {
        id: null,
        name: null,
        logo_name: null,
        city: null,
        town: null,
        district: null,
        address: null,
        telephone: null,
        email: null,
        currency_unit_id: null,
        logo_code: null,
        logo_id: null,
        bank_accounts: {
          bank_name: null,
          bank_branch: null,
          debtor_account: null,
          iban: null,
          account_holder_name: null,
          currency_unit_id: null,
        },
      },
      supplierCompanies: [],
      selectedCompaniesId: null,
    };
  },
  methods: {
    getCurrencyUnits() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/currencies",
        })
        .then((result) => {
          if (result.status) {
            self.currency_units = self.convertArrayToObjectByKey(
              result.data,
              "id",
              "code"
            );
            self.currency_units_raw = result.data;
          }
        });
    },
    getAllSupplierCompanies() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/supplier-companies/logo/sync",
        })
        .then((result) => {
          if (result.status) {
            self.supplierCompanies = result.data;
          }
        });
    },
    getSupplierCompanies() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/supplier-companies/" + this.id,
        })
        .then((result) => {
          if (result.status) {
            self.company = result.data;
            if (self.company.bank_accounts.length <= 0) {
              self.company.bank_accounts = self.bank_accounts;
            } else {
              self.company.bank_accounts = result.data.bank_accounts[0];
            }
          }
        });
    },
    createOrUpdate() {
      let self = this;
      this.$store
        .dispatch(this.id ? UPDATE_ITEM_BY_ID : CREATE_ITEM, {
          url: this.id ? "api/supplier-companies/" + this.id : "api/supplier-companies",
          contents: this.company,
        })
        .then((result) => {
          if (result.status) {
            self.sweetAlertSuccess(self.$t("general.successfully_saved")).then((_) => {
              self.$router.go(-1);
            });
          }
        });
    },
  },
  mounted() {
    let id = this.$route.params.id;
    this.getCurrencyUnits();
    if (id != null) {
      this.id = id;
      this.getSupplierCompanies();
    }
    this.getAllSupplierCompanies();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      {
        title: this.$t("general.supplier_company"),
        route: "management.supplier-company.index",
      },
      {
        title: this.$t(
          this.id ? "general.edit_supplier_company" : "general.create_supplier_company"
        ),
      },
    ]);
  },
  watch: {
    selectedCompaniesId(newValue) {
      let selectedItem = this.supplierCompanies.find(
        (item) => item.logicalref == newValue
      );
      let selectedCurrencyUnits = this.currency_units_raw.find(
        (item) => item.logo_currency_id == selectedItem.ccurrency
      );
      let defaultCurrencyUnits = this.currency_units_raw.find(
        (item) => item.logo_currency_id == 160
      );

      this.company.name = selectedItem.title;
      this.company.logo_name = selectedItem.title;
      this.company.city = selectedItem.city;
      this.company.town = selectedItem.town;
      this.company.district = selectedItem.district;
      this.company.address = selectedItem.addresS1 + " " + selectedItem.addresS2;
      this.company.telephone = selectedItem.telephonE1;
      this.company.email = selectedItem.e_MAIL;
      this.company.logo_code = selectedItem.code;
      this.company.logo_id = selectedItem.logicalref;
      this.company.currency_unit_id = selectedCurrencyUnits
        ? selectedCurrencyUnits.id
        : defaultCurrencyUnits.id;
    },
  },
};
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
